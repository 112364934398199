import { graphql, PageProps } from "gatsby"
import { useEffect, useState } from "react"
import tw from "twin.macro"
import AnalyticsEventsClient from "../../clients/AnalyticsEvents"
import Seo from "../../components/SEO"
import ViewBooths from "../../components/ViewBooths"

const RegistrationPage: React.FC<{visitorId: string}>= ({visitorId}) => {

  const [sponsors, setSponsors] = useState(null)  

    // Event Analytics for page view
    useEffect(() => {
      AnalyticsEventsClient.event({
        eventType: "view",
        eventName: "view_sponsors_web",
        eventMessage: "User viewed sponsors",
        visitorID: visitorId
      })
    }, [])
  
  useEffect(() => {
        fetch(`${process.env.GATSBY_API_ROOT_URL}/visitor-sponsors/${visitorId}`)
        .then(response => response.json())
        .then(data => setSponsors(data))
    
    }, [])

  return (
  <div css={[tw`z-50`]}>
    <Seo title="Suggested Booths" />
    <ViewBooths data={sponsors}/>
  </div>
  )
}

export default RegistrationPage
