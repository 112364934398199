import * as React from "react"
import tw from "twin.macro"
import MobileBG from "../../images/mobile-background.jpg"
import { MobileLogo } from "../../components/Icons/MobileLogo"
import { Booth, SuggestBoothProps } from "./ViewBooths.d"
import YAMLData from "../../data/suggested_booths.yml"
import { Link } from "gatsby"
import { ButtonLink } from "../Buttons/Button"

const ViewBooths: React.FC<SuggestBoothProps> = ({ data }) => {
  const copy = YAMLData[0]

  return (
    <div css={[tw`h-screen bg-[#b32a24] min-h-full max-h-full relative`]}>
      <section
        css={[tw`bg-cover h-96 pb-24`, `background-image: url(${MobileBG});`]}
      >
        <div css={[tw`container justify-center py-5 px-5`]}>
          <div css={[tw`flex-col justify-items-center h-3/6`]}>
            <h1 css={[tw`text-xl text-center text-beige p-2`]}>
              {copy.greeting}
            </h1>
            <div css={[tw`flex justify-center mx-auto`]}>
              <MobileLogo css={[tw`text-white h-20`]} />
            </div>
            <h1 css={[tw`text-2xl leading-snug text-white text-center pt-8 `]}>
              {copy.headline}
            </h1>
          </div>
        </div>
      </section>
      <div
        className="no-scrollbar"
        css={[
          tw`flex flex-col mx-auto overflow-x-auto w-full absolute  -mt-28 z-50`,
        ]}
      >
        <ul css={[tw`flex flex-row mx-auto gap-2 px-5`]}>
          {data?.map((item: Booth, index: number) => (
            <li key={item.name} css={[index != data.length - 1]}>
              <div
                css={[
                  tw`flex flex-col justify-between  bg-white text-2xl text-center text-gray-700 shadow-md p-8 h-48 w-48`,
                ]}
              >
                {item.booth}
                <img
                  src={`${process.env.GATSBY_API_ROOT_URL}/Assets/${item.logo}`}
                  alt={`${item.name} logo`}
                />
              </div>
            </li>
          ))}
        </ul>
      </div>

      <div
        css={[
          tw`flex content-end items-end mx-auto px-5 pb-8 bg-gradient-to-b from-red-300 to-[#b32a24] h-96 pt-24`,
        ]}
      >
        <div css={[tw`mx-auto grid grid-cols-1 `]}>
          <h1
            css={[
              tw`text-xl text-white text-center leading-snug pt-8 col-span-1`,
            ]}
          >
            {copy.prompt}
          </h1>
          <ButtonLink to="/check-registration" css={[tw`mx-auto`]} primary>
            Register
          </ButtonLink>
        </div>
      </div>
    </div>
  )
}

export default ViewBooths
