import React from "react"

export const MobileLogo = ({
  color = "currentColor",
  ...props
}: React.SVGProps<SVGSVGElement>) => (
  <svg width="741" height="256"  viewBox="0 0 741 256" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fill={color} fillRule="evenodd">
      <g fillRule="nonzero">
        <path d="m191.236 207.856 31.37-176.709c.158-.472.473-.944 1.104-.944L270.372 28c.788 0 1.26.63 1.26 1.416l-30.897 174.821c-1.419 8.497 2.05 13.375 9.616 13.375 2.05 0 4.414-.472 6.148-1.101.946-.315 1.734.472 1.42 1.416l-9.144 33.989c-.158.472-.473.787-.946.787-8.197 1.888-15.291 2.99-23.646 2.99-25.065-.158-39.253-13.848-32.947-47.837M323.825 166.574c-.157.789.314 1.735 1.1 1.735 1.887.158 4.088.158 6.446.158 31.755 0 48.89-10.726 48.89-26.026 0-9.148-5.816-15.142-15.72-15.142-15.878 0-34.585 14.669-40.716 39.275m-.315 17.193c-1.257-1.104-2.043-.631-1.886 1.104 2.044 18.454 14.777 32.02 40.087 32.02 21.38 0 36.629-5.521 49.52-12.62.943-.472 1.886.316 1.729 1.263l-10.533 38.328c0 .316-.314.631-.629.79-12.262 7.097-31.912 11.04-55.336 11.04-46.847 0-74.83-27.13-74.83-70.033 0-53.47 39.93-95.743 96.682-95.743 38.672 0 59.108 20.19 59.108 48.424 0 31.23-29.397 54.26-70.742 54.26-13.362 0-25.938-2.84-33.17-8.833" />
        <path
          d="M602.97 93.91h-55.343c-.625 0-1.25.315-1.563.787l-120.38 154.015c-.782 1.257.313 2.986 1.876 2.986h56.438c.625 0 1.25-.315 1.563-.786L605.003 96.897c.626-1.257-.469-2.986-2.032-2.986z"
          opacity=".52"
        />
        <path d="m485.344 171.862 25.478 45.882 34.441-43.997-25.32-45.882zM174.011 216.77l-9.076 34.037c-.156.473-.47.788-.939.788-6.729 1.576-12.675 2.521-19.248 2.837-1.251 0-2.503.157-3.755.157h-.47c-15.805 0-27.071-5.515-31.766-18.122-.47-.157-.939-.157-1.252.158-12.675 11.976-28.95 19.067-47.415 19.067-33.8 0-60.09-30.098-60.09-73.748.156-54.208 36.461-92.028 77.616-92.028 18.779 0 35.053 7.092 45.85 21.747.157.157.314.315.626.315.313 0 .783-.158.783-.473l2.347-13.71c.157-.63.626-.945 1.096-.945h46.32c.782 0 1.251.63 1.094 1.418l-18.934 104.95c-1.252 8.036 1.721 12.764 8.294 13.236.469 0 .782.158 1.251.158 1.252 0 2.504-.158 3.756-.473.626-.157 1.252-.315 1.721-.472h.47c1.095-.63 2.034.157 1.721 1.103zm-82.624-83.361c-22.22-4.412-35.365 12.922-38.808 32.777-3.756 21.746 2.973 41.444 22.534 45.699 16.9 3.624 33.487-6.146 38.808-32.777 4.382-21.747-3.13-41.917-22.534-45.7z" />
        <path d="M581.206 217.98c-8.65 0-14.942-3.771-21.705-16.813l-48.126-87.052c-9.122-18.228-19.503-24.199-38.061-24.199-5.033 0-12.268.943-19.974 2.514a1.61 1.61 0 0 0-.944.786l-8.965 33.155c-.157.629.315 1.257.944 1.257h3.46c7.707 0 14.312 3.457 20.918 16.028l48.126 86.424c9.122 17.756 17.458 25.612 38.376 25.612 6.92 0 14.312-1.414 22.333-2.828a1.61 1.61 0 0 0 .944-.786l8.65-33.626c.157-.786-.472-1.415-1.1-1.257-1.416.314-3.776.785-4.876.785z" />
      </g>
      <text
        fontFamily="WorkSans-ExtraBold, Work Sans"
        fontSize="48"
        fontWeight="700"
        letterSpacing="-.385"
      >
        <tspan x="291.672" y="45">
          WORKFORCE EXPO
        </tspan>
      </text>
    </g>
  </svg>
)
